/* eslint-disable max-len */
import React from 'react';

import './contact-us.scss';

const Illustration = () => (
  <svg viewBox="0 0 1600 1200" version="1.1">
    <g>
      <path
        d="M435.97 374.48L501.14 318.33C504.23 315.66 504.91 311.03 502.57 307.59L501.37 305.82 497.78 300.42C474.64 267.29 555.26 213.61 577.12 246.46L581.21 252.63 582.42 254.41C584.68 257.83 589.2 258.99 592.86 257.2L670.31 218.4C674.36 216.36 675.94 211.43 673.9 207.38 673.71 207.08 673.6 206.81 673.41 206.52L673.35 206.42 667.17 197.19 662.02 189.36 656.87 181.53C635.94 150.14 563.82 161.62 494.87 208.65 426.2 254.4 386.63 316.42 407.5 347.71L423.67 372.87 423.79 372.73C426.22 376.53 431.35 377.57 435.07 375.12 435.46 374.87 435.76 374.69 435.97 374.48Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M436.51 354.96L501.67 298.81C504.77 296.14 505.45 291.52 503.11 288.07L501.91 286.3 498.31 280.9C475.18 247.77 555.8 194.09 577.66 226.94L581.75 233.12 582.95 234.89C585.22 238.32 589.74 239.47 593.4 237.68L670.84 198.88C674.89 196.84 676.48 191.91 674.43 187.86 674.25 187.57 674.14 187.29 673.95 187L673.89 186.9 667.71 177.67 662.55 169.84 657.4 162.01C636.48 130.62 564.36 142.11 495.41 189.13 426.74 234.88 387.17 296.9 408.03 328.2L424.21 353.35 424.33 353.21C426.76 357.01 431.89 358.05 435.61 355.6 436 355.35 436.29 355.17 436.51 354.96Z"
        fill="#04dce7"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1229.55 926.49C1226.11 927.43 1222.6 928.04 1219.06 928.3L831.14 955.91C820.28 956.63 809.67 954.01 801.03 948.46 792.39 942.91 786.22 934.76 783.54 925.33L641.35 411.21C638.9 402.45 639.6 393.06 643.35 384.37 647.1 375.68 653.7 368.14 662.23 362.81L999.42 151.34C1003.79 148.66 1008.55 146.59 1013.53 145.19 1025.86 141.79 1038.9 142.92 1049.79 148.32 1060.66 153.72 1068.51 162.96 1071.58 174L1264.58 871.85C1265.46 874.94 1265.91 878.14 1265.95 881.37 1266.14 891.24 1262.64 901.07 1256.04 909.26 1249.42 917.45 1240.09 923.52 1229.55 926.49H1229.55Z"
        fill="#2c485b"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M570.51 380.76L907.74 282.21C962.51 266.21 1019.82 297.59 1035.82 352.37L1134.37 689.6C1150.38 744.37 1118.99 801.67 1064.22 817.68L726.99 916.22C672.21 932.23 614.91 900.84 598.9 846.07L500.36 508.84C484.35 454.07 515.85 396.73 570.51 380.76Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1326.16 378.28L1334.43 417.65 1307.85 392.02 1245.58 419.5 1288.64 374.59 1259.31 341.58 1302.38 351.69 1325.27 303.1 1322.48 352.58 1365.54 344.38 1326.16 378.28Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1329.26 364.97L1337.53 404.34 1310.94 378.71 1248.67 406.19 1291.73 361.28 1262.41 328.27 1305.47 338.38 1328.37 289.79 1325.57 339.27 1368.63 331.07 1329.26 364.97Z"
        fill="#04dce7"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1340.2 375.91L1348.47 415.28 1321.88 389.65 1259.61 417.13 1302.67 372.22 1273.35 339.27 1316.41 349.32 1339.31 300.79 1336.57 350.28 1379.64 342.01 1340.2 375.91Z"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M501.65 907.99L622.4 982.95 560.51 1045.89 501.65 907.99Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M501.65 907.99L654.87 983.31 592.98 1046.26 501.65 907.99Z"
        fill="#2c485b"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M232.56 613.85L256.94 534.38C257.01 534.33 257.08 534.28 257.13 534.35L351.98 656.67C352.03 656.74 351.96 656.79 351.89 656.84L232.72 614.06C232.54 613.97 232.54 613.97 232.56 613.85Z"
        fill="#141515"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M223.11 580.78L247.49 501.31C247.51 501.19 247.63 501.21 247.68 501.28L351.98 656.67C352.03 656.74 351.96 656.79 351.83 656.77L223.09 580.9C223.04 580.83 223.04 580.83 223.11 580.78Z"
        fill="#61fdff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1369.81 803.77L1351.48 790.21 1367.39 777.13C1367.66 776.89 1367.74 776.49 1367.59 776.17 1367.43 775.85 1367.03 775.66 1366.67 775.77L1347.47 780.51 1344.72 760.86C1344.68 760.51 1344.37 760.19 1344.01 760.19 1343.61 760.15 1343.29 760.39 1343.17 760.79L1337.49 781.14 1312.16 774.58C1311.76 774.5 1311.41 774.66 1311.25 775.02 1311.09 775.38 1311.21 775.81 1311.52 776.01L1331.96 789.17 1311.01 806.35C1310.69 806.59 1310.61 807.03 1310.81 807.38 1311.01 807.7 1311.41 807.86 1311.8 807.7L1336.37 797.8 1339.79 816.05C1339.87 816.41 1340.19 816.69 1340.55 816.69 1340.55 816.69 1340.55 816.69 1340.59 816.69 1340.95 816.69 1341.26 816.45 1341.34 816.09L1345.96 798.28 1369.06 805.2C1369.41 805.32 1369.81 805.12 1370.01 804.8 1370.21 804.4 1370.13 804 1369.81 803.77Z"
        fill="#61fdff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1325.88 759.31L1321.78 752.36"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1357.05 766.71L1359.47 763.01"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1364.8 817.92L1368.9 822.01"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1309.93 789.65H1301.31"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M392.48 757.47C382.69 764.29 380.27 777.75 387.08 787.54 393.89 797.33 407.35 799.75 417.14 792.94 426.94 786.13 429.35 772.67 422.54 762.88 415.73 753.08 402.27 750.66 392.48 757.47Z"
        fill="#04dce7"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M325.79 750C318.53 755.05 316.74 765.02 321.79 772.28 326.84 779.54 336.81 781.33 344.07 776.28 351.33 771.24 353.12 761.26 348.07 754 343.03 746.74 333.05 744.95 325.79 750Z"
        fill="#2c485b"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M726.58 413.18L644.07 546.11C632.25 565.13 628.89 588.18 634.66 609.79L673.65 755.7C685.28 799.3 730.13 825.33 773.83 813.61L960.75 763.76C1004.45 752.14 1030.39 707.29 1018.77 663.58L979.77 517.68C974.01 496.07 959.5 477.72 939.81 467.16L802.07 393C775.47 378.79 742.43 387.53 726.58 413.18Z"
        fill="#2c485b"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M767.11 413.66C774.6 411.64 782.58 412.6 789.49 416.25L927.23 490.4C940.48 497.6 950.19 509.9 954.12 524.5L993.12 670.5C1001 700.08 983.42 730.34 953.93 738.31L766.92 788.06C737.34 795.94 707.08 778.36 699.11 748.88L660.21 602.97C656.36 588.37 658.67 572.91 666.55 560.04L748.96 427.1C753.09 420.48 759.52 415.67 767.11 413.66ZM760.29 388.11C746.27 391.85 734.26 400.78 726.58 413.18L644.07 546.11C632.25 565.13 628.89 588.18 634.66 609.79L673.65 755.7C685.28 799.3 730.13 825.33 773.83 813.61L960.75 763.76C1004.45 752.14 1030.39 707.29 1018.77 663.58L979.77 517.68C974.01 496.07 959.5 477.72 939.81 467.16L802.07 393C789.2 386.09 774.32 384.36 760.29 388.11Z"
        fill="#61fdff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M887.69 539.77L693.45 591.6 742.88 776.83 937.12 725.01 887.69 539.77Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M899.7 520.27L705.46 572.09 754.88 757.33 949.12 705.51 899.7 520.27Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M835.5 670.02L633.6 605.95 673.56 755.6C685.18 799.21 730.03 825.24 773.74 813.52L960.56 763.67C1004.26 752.05 1030.2 707.19 1018.57 663.58L978.62 513.93 835.5 670.02Z"
        fill="#61fdff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M823.49 625.07L683.35 778.08C702.47 808.43 739.06 822.84 773.74 813.62L960.56 763.67C995.23 754.45 1019.82 723.71 1021.26 687.88L823.49 625.07Z"
        fill="#61fdff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M817.25 727.36L879.87 710.65C888.71 708.25 897.74 713.53 900.14 722.37 902.54 731.2 897.26 740.23 888.42 742.63L825.8 759.35C816.96 761.75 807.93 756.46 805.53 747.63 803.13 738.69 808.41 729.67 817.25 727.36Z"
        fill="#2c485b"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M829.16 741.19L876.42 728.61"
        stroke="#181818"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M683.26 778.75L793.33 657.15"
        stroke="#181818"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M865.28 638.32L1020.5 687.5"
        stroke="#181818"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M662.13 612.1L635.71 604.13"
        stroke="#181818"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M957.2 534.3L976.79 514.22"
        stroke="#181818"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M112.9 670.31C216.95 743.14 447.93 651.58 590.13 563.14 748.28 464.64 753.48 361.63 689.67 362.68 600.88 364.06 385.5 641.17 514.17 699.09 620.3 747.3 811.4 655.39 862.04 710.88 912.67 766.37 936.26 890.54 1075.33 952.27"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
  </svg>
);

export const ContactUs = () => (
  <div id="Contact" className="contactUs">
    <div className="container">
      <div className="text">
        <div>
          <div className="title">Reach out to our Team</div>
          <div className="subtitle">Contact Us</div>
        </div>
        <div className="bottom">
          <div className="socials">
            <a
              target="_blank"
              href="https://www.instagram.com/eqlze.ai/"
              rel="noreferrer"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
              >
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                <path d="M17.5 6.5h.01" />
              </svg>
              <div>@Eqlze.ai</div>
            </a>
            <a target="_blank" href="tel:8147915905" rel="noreferrer">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
              >
                <rect width="14" height="20" x="5" y="2" rx="2" ry="2" />
                <path d="M12 18h.01" />
              </svg>
              <div>+1 814-791-5905</div>
            </a>
            <a target="_blank" href="email:email@email.com" rel="noreferrer">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                <path d="m22 6-10 7L2 6" />
              </svg>
              <div>email@email.com</div>
            </a>
          </div>
        </div>
      </div>
      <div className="logo">
        <Illustration />
      </div>
    </div>
  </div>
);
