/* eslint-disable max-len */
import React from 'react';
import { Text } from '@nextui-org/react';

import './hero.scss';

const Illustration = () => (
  <svg viewBox="0 0 1600 1200" version="1.1">
    <rect fill="transparent" />
    <g>
      <path
        d="M1229.43 789.89H1332.74C1357.21 789.89 1377.04 809.72 1377.04 834.19V937.5C1377.04 961.97 1357.21 981.8 1332.74 981.8H1229.43C1204.96 981.8 1185.13 961.97 1185.13 937.5V834.19C1185.13 809.72 1204.96 789.89 1229.43 789.89Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1146.88 199.07H1223.39C1241.51 199.07 1256.2 213.76 1256.2 231.88V308.39C1256.2 326.51 1241.51 341.2 1223.39 341.2H1146.88C1128.76 341.2 1114.07 326.51 1114.07 308.39V231.88C1114.07 213.76 1128.76 199.07 1146.88 199.07Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M981.88 559.11L911.63 741.08 1091.18 914.84 1334.55 766.64 1315.75 666.81 1217.53 577.76 981.88 559.11Z"
        stroke="#231f20"
        strokeWidth="4"
        opacity="1"
        fill="none"
      />
      <path
        d="M988.31 566.02L1072.38 693.34 1091.18 906.65"
        stroke="#231f20"
        strokeWidth="4"
        opacity="1"
        fill="none"
      />
      <path
        d="M911.63 741.08L1072.38 693.34 1304.65 666.81"
        stroke="#231f20"
        strokeWidth="4"
        opacity="1"
        fill="none"
      />
      <path
        d="M1209.81 582.1L1075.91 686.42"
        stroke="#231f20"
        strokeWidth="4"
        opacity="1"
        fill="none"
      />
      <path
        d="M1083.15 699.77L1326.52 761.97"
        stroke="#231f20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M911.63 760.37C921.93 760.37 930.28 752.02 930.28 741.72 930.28 731.42 921.93 723.07 911.63 723.07 901.33 723.07 892.98 731.42 892.98 741.72 892.98 752.02 901.33 760.37 911.63 760.37Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1072.38 711.98C1082.68 711.98 1091.02 703.64 1091.02 693.34 1091.02 683.03 1082.68 674.69 1072.38 674.69 1062.07 674.69 1053.73 683.03 1053.73 693.34 1053.73 703.64 1062.07 711.98 1072.38 711.98Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1341.72 783.87C1351.24 779.93 1355.76 769.01 1351.82 759.5 1347.86 750 1336.96 745.47 1327.45 749.42 1317.93 753.36 1313.41 764.26 1317.35 773.77 1321.29 783.29 1332.21 787.81 1341.72 783.87Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1091.18 933.49C1101.49 933.49 1109.83 925.15 1109.83 914.84 1109.83 904.54 1101.49 896.2 1091.18 896.2 1080.88 896.2 1072.54 904.54 1072.54 914.84 1072.54 925.15 1080.88 933.49 1091.18 933.49Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1315.75 685.46C1326.05 685.46 1334.39 677.12 1334.39 666.81 1334.39 656.51 1326.05 648.17 1315.75 648.17 1305.44 648.17 1297.1 656.51 1297.1 666.81 1297.1 677.12 1305.44 685.46 1315.75 685.46Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M981.88 577.76C992.18 577.76 1000.52 569.42 1000.52 559.11 1000.52 548.81 992.18 540.47 981.88 540.47 971.57 540.47 963.23 548.81 963.23 559.11 963.23 569.42 971.57 577.76 981.88 577.76Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1217.53 596.41C1227.83 596.41 1236.18 588.06 1236.18 577.76 1236.18 567.46 1227.83 559.11 1217.53 559.11 1207.23 559.11 1198.88 567.46 1198.88 577.76 1198.88 588.06 1207.23 596.41 1217.53 596.41Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M393.25 226.46H727.94C753.76 226.46 774.66 249 774.66 276.95V698.6C774.66 726.44 753.76 748.98 727.94 748.98H393.25C367.43 748.98 346.52 726.44 346.52 698.6V276.95C346.52 249.11 367.43 226.46 393.25 226.46Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M744.76 282.02H376.04V713.42H744.76V282.02Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M446.71 361.91H522.91C526 361.93 528.96 363.16 531.15 365.35 533.34 367.54 534.56 370.5 534.59 373.58V373.58C534.56 376.67 533.34 379.63 531.15 381.82 528.96 384.01 526 385.24 522.91 385.26H446.71C443.63 385.24 440.67 384.01 438.48 381.82 436.29 379.63 435.06 376.67 435.04 373.58V373.58C435.06 370.5 436.29 367.54 438.48 365.35 440.67 363.16 443.63 361.93 446.71 361.91V361.91Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M569.62 361.91H596.66C599.74 361.93 602.7 363.16 604.89 365.35 607.08 367.54 608.31 370.5 608.33 373.58V373.58C608.31 376.67 607.08 379.63 604.89 381.82 602.7 384.01 599.74 385.24 596.66 385.26H569.62C566.53 385.24 563.57 384.01 561.38 381.82 559.19 379.63 557.97 376.67 557.94 373.58V373.58C557.97 370.5 559.19 367.54 561.38 365.35 563.57 363.16 566.53 361.93 569.62 361.91V361.91Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M642.13 361.91H669.17C672.26 361.93 675.22 363.16 677.4 365.35 679.59 367.54 680.82 370.5 680.85 373.58V373.58C680.82 376.67 679.59 379.63 677.4 381.82 675.22 384.01 672.26 385.24 669.17 385.26H642.13C639.05 385.24 636.08 384.01 633.9 381.82 631.71 379.63 630.48 376.67 630.46 373.58 630.48 370.5 631.71 367.54 633.9 365.35 636.08 363.16 639.05 361.93 642.13 361.91Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M446.71 548.11H522.91C526 548.13 528.96 549.36 531.15 551.55 533.34 553.74 534.56 556.7 534.59 559.79V559.79C534.56 562.87 533.34 565.83 531.15 568.02 528.96 570.21 526 571.44 522.91 571.46H446.71C443.63 571.44 440.67 570.21 438.48 568.02 436.29 565.83 435.06 562.87 435.04 559.79V559.79C435.06 556.7 436.29 553.74 438.48 551.55 440.67 549.36 443.63 548.13 446.71 548.11Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M569.62 548.11H669.17C672.26 548.13 675.22 549.36 677.4 551.55 679.59 553.74 680.82 556.7 680.85 559.79V559.79C680.82 562.87 679.59 565.83 677.4 568.02 675.22 570.21 672.26 571.44 669.17 571.46H569.62C566.53 571.44 563.57 570.21 561.38 568.02 559.19 565.83 557.97 562.87 557.94 559.79V559.79C557.97 556.7 559.19 553.74 561.38 551.55 563.57 549.36 566.53 548.13 569.62 548.11V548.11Z"
        fill="#f3ebe9"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M446.71 486.04H564.7C567.79 486.07 570.75 487.3 572.94 489.48 575.12 491.67 576.35 494.63 576.38 497.72 576.35 500.8 575.12 503.77 572.94 505.95 570.75 508.14 567.79 509.37 564.7 509.39H446.71C443.63 509.37 440.67 508.14 438.48 505.95 436.29 503.77 435.06 500.8 435.04 497.72V497.72C435.06 494.63 436.29 491.67 438.48 489.48 440.67 487.3 443.63 486.07 446.71 486.04Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M520.46 423.98H596.66C599.74 424 602.7 425.23 604.89 427.42 607.08 429.6 608.31 432.57 608.33 435.65V435.65C608.31 438.74 607.08 441.7 604.89 443.89 602.7 446.07 599.74 447.3 596.66 447.33H520.46C517.37 447.3 514.41 446.07 512.22 443.89 510.03 441.7 508.8 438.74 508.78 435.65V435.65C508.8 432.57 510.03 429.6 512.22 427.42 514.41 425.23 517.37 424 520.46 423.98V423.98Z"
        fill="#f3ebe9"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M446.71 423.98H473.75C476.84 424 479.8 425.23 481.99 427.42 484.17 429.6 485.4 432.57 485.43 435.65 485.4 438.74 484.17 441.7 481.99 443.89 479.8 446.07 476.84 447.3 473.75 447.33H446.71C443.63 447.3 440.67 446.07 438.48 443.89 436.29 441.7 435.06 438.74 435.04 435.65V435.65C435.06 432.57 436.29 429.6 438.48 427.42 440.67 425.23 443.63 424 446.71 423.98V423.98Z"
        fill="#f4fae3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M642.13 423.98H669.17C672.26 424 675.22 425.23 677.4 427.42 679.59 429.6 680.82 432.57 680.85 435.65V435.65C680.82 438.74 679.59 441.7 677.4 443.89 675.22 446.07 672.26 447.3 669.17 447.33H642.13C639.05 447.3 636.08 446.07 633.9 443.89 631.71 441.7 630.48 438.74 630.46 435.65V435.65C630.48 432.57 631.71 429.6 633.9 427.42 636.08 425.23 639.05 424 642.13 423.98Z"
        fill="#f3ebe9"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M520.46 610.18H669.17C672.26 610.2 675.22 611.43 677.4 613.62 679.59 615.81 680.82 618.77 680.85 621.85V621.85C680.82 624.94 679.59 627.9 677.4 630.09 675.22 632.27 672.26 633.5 669.17 633.53H520.46C517.37 633.5 514.41 632.27 512.22 630.09 510.03 627.9 508.8 624.94 508.78 621.85V621.85C508.8 618.77 510.03 615.81 512.22 613.62 514.41 611.43 517.37 610.2 520.46 610.18V610.18Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M446.71 610.18H473.75C476.84 610.2 479.8 611.43 481.99 613.62 484.17 615.81 485.4 618.77 485.43 621.85 485.4 624.94 484.17 627.9 481.99 630.09 479.8 632.27 476.84 633.5 473.75 633.53H446.71C443.63 633.5 440.67 632.27 438.48 630.09 436.29 627.9 435.06 624.94 435.04 621.85V621.85C435.06 618.77 436.29 615.81 438.48 613.62 440.67 611.43 443.63 610.2 446.71 610.18Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M688.31 264.37L739.06 315.12"
        stroke="#231f20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M739.06 264.37L688.31 315.12"
        stroke="#231f20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M415.6 856.3H258.55C249.9 856.3 242.84 849.25 242.84 840.6V730.1C242.84 721.45 249.9 714.39 258.55 714.39H301C306.57 714.39 311.7 717.35 314.54 722.13L319.43 730.44C322.28 735.22 327.4 738.18 332.98 738.18H415.71C424.36 738.18 431.42 745.23 431.42 753.88V840.6C431.42 849.25 424.36 856.3 415.6 856.3 415.71 856.3 415.71 856.3 415.6 856.3Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1215.16 588.7L1256.46 562.95H1366.27C1380.84 562.93 1394.81 557.14 1405.11 546.84 1415.42 536.53 1421.2 522.56 1421.22 507.99 1421.2 493.43 1415.42 479.46 1405.11 469.15 1394.81 458.85 1380.84 453.06 1366.27 453.04H1270.11C1255.54 453.06 1241.57 458.85 1231.26 469.15 1220.96 479.46 1215.17 493.43 1215.16 507.99V588.7Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1276.72 518.13C1282.32 518.13 1286.85 513.59 1286.85 507.99 1286.85 502.4 1282.32 497.86 1276.72 497.86 1271.12 497.86 1266.59 502.4 1266.59 507.99 1266.59 513.59 1271.12 518.13 1276.72 518.13Z"
        fill="#a8a8a8"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1318.1 518.13C1323.7 518.13 1328.23 513.59 1328.23 507.99 1328.23 502.4 1323.7 497.86 1318.1 497.86 1312.51 497.86 1307.97 502.4 1307.97 507.99 1307.97 513.59 1312.51 518.13 1318.1 518.13Z"
        fill="#a8a8a8"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1359.66 518.13C1365.26 518.13 1369.79 513.59 1369.79 507.99 1369.79 502.4 1365.26 497.86 1359.66 497.86 1354.06 497.86 1349.53 502.4 1349.53 507.99 1349.53 513.59 1354.06 518.13 1359.66 518.13Z"
        fill="#a8a8a8"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M169.41 590.78C197.65 610.55 260.35 585.69 298.95 561.69 341.88 534.95 343.3 506.99 325.97 507.27 301.87 507.65 243.41 582.87 278.34 598.59 307.14 611.68 359.02 586.73 372.76 601.79 386.51 616.86 392.91 650.56 430.66 667.32"
        stroke="#231f20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M522.35 968.69C507.73 971.08 497.81 984.86 500.2 999.48 502.58 1014.1 516.37 1024.01 530.99 1021.63 545.6 1019.25 555.52 1005.46 553.14 990.84 550.75 976.22 536.97 966.31 522.35 968.69Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M451.64 924.6C440.81 926.37 433.46 936.58 435.23 947.42 436.99 958.25 447.21 965.6 458.05 963.84 468.88 962.07 476.23 951.85 474.46 941.02 472.7 930.18 462.48 922.83 451.64 924.6Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M226.2 487.5C235.84 487.5 243.66 479.69 243.66 470.04 243.66 460.4 235.84 452.58 226.2 452.58 216.56 452.58 208.74 460.4 208.74 470.04 208.74 479.69 216.56 487.5 226.2 487.5Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M955.35 175.76C968.26 175.76 978.72 186.23 978.72 199.13 978.72 204.96 976.52 210.36 973 214.46 968.63 219.39 962.43 222.44 955.35 222.44 947.1 222.44 939.85 218.12 935.72 211.83 933.37 208.19 931.98 203.79 931.98 199.13 931.98 186.23 942.45 175.76 955.35 175.76Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1120.11 313.99L1152.33 241.31 1149.74 317.77 1213.57 316.28 1156.11 350.68 1186.43 413.52 1132.34 368.88 1099.04 425.95 1106.89 360.72 1041.27 362.31 1102.12 330.1 1063.94 273.92 1120.11 313.99Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M328.07 318.39L413.7 346.8 330.12 363.73 337.22 430.93 291.47 375.21 237.25 423.69 267.44 349.8 186.18 341.88 267.3 322.35 234.39 258.58 296.53 304.05 336.95 241.78 328.07 318.39Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M638.59 918.94L633.47 989.88 670.22 924.06 720.89 973.5 694.79 901.64 779.44 897.14 694.79 872.16 730.51 800.61 665.72 849.44 628.66 785.15 636.13 862.23 556.39 850.05 620.47 891.41 568.06 935.94 638.59 918.94Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1018.12 1055.28C1025.52 1057.09 1073.9 1031.86 1075.6 1031 1075.6 1031 1075.6 1031 1075.6 1030.91 1076.93 1005.96 1075.22 980.54 1070.67 956.07 1070.67 956.07 1070.67 955.97 1070.58 955.97L1022.96 946.39 980.37 956.44C980.27 956.44 980.27 956.54 980.27 956.54 989.38 977.31 995.93 993.82 997.63 1005.86 997.73 1006.24 1010.72 1053.48 1018.12 1055.28Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M878.3 1050.54C885.42 1052.25 937.97 1031.86 939.77 1031.1 939.77 1031.1 939.87 1031.1 939.87 1031 942.24 1011.84 944.8 996.09 945.27 974.37 945.37 968.3 945.08 962.14 944.89 955.88 944.89 955.88 944.89 955.78 944.8 955.78L902.68 949.99 855.82 955.59C855.73 955.59 855.73 955.69 855.73 955.69 858.2 971.05 859.9 985.28 860.09 996.95 860.09 1000.08 860.09 1002.83 860 1005.58 860 1005.68 860 1005.68 860.09 1005.68 874.23 1006.15 870.81 1048.64 878.3 1050.54Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M721.02 394.14C719.03 392.15 711.73 395 712.49 405.05 712.49 405.14 712.39 405.24 712.3 405.14 703.67 388.83 681.19 356.39 675.59 373.18 675.59 373.27 675.49 373.27 675.49 373.27 668 368.72 664.3 376.12 666.1 382.66 666.1 382.76 666.1 382.76 666.01 382.76 657.66 382.85 658.51 391.2 660.41 395.94V396.04C651.12 401.82 670.66 434.64 671.89 435.78 674.64 444.32 679.48 460.35 687.07 468.03 687.07 468.03 687.07 468.03 687.07 468.13 686.69 476.95 686.59 485.49 686.78 493.17 686.78 493.27 686.78 493.27 686.88 493.27L717.7 495.07C717.7 495.07 717.7 495.07 717.8 495.07L748.72 472.78V472.68C742.08 446.6 730.51 403.63 721.02 394.14Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M839.6 328.88C850.8 304.31 879.25 297.29 907.71 312.66 932.09 326.13 928.96 341.02 927.25 360.94 926.78 366.16 929.15 387.41 927.72 403.34 927.34 407.71 926.78 411.69 926.4 415.1L926.11 416.34 859.9 418.23C859.81 418.23 859.71 415.29 859.71 415.29L860.38 410.74C860.38 410.65 860.28 410.65 860.28 410.65 823.57 417.48 816.46 391.96 826.13 365.31V365.21C818.45 357.72 819.97 352.88 831.35 352.03 831.35 352.03 831.45 352.03 831.45 351.93 832.68 346.24 837.14 334.19 839.6 328.88Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M860.37 410.74L859.7 415.1V415.2L858.94 425.63C858.94 425.73 859.04 425.73 859.13 425.73L879.72 404.86C879.81 404.77 879.72 404.67 879.62 404.67 868.81 408.65 861.13 410.46 860.37 410.74 860.37 410.65 860.37 410.65 860.37 410.74Z"
        fill="#171717"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M835.23 375.17C835.23 375.17 840.07 376.31 843.96 371.85"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M860.37 410.65C864.16 409.7 876.02 406.47 879.62 404.77"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M884.75 380.2L893.57 373.56"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M844.24 349.75C848.13 349.84 848.13 355.54 844.24 355.63 840.35 355.54 840.35 349.84 844.24 349.75Z"
        fill="#17151c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M931.42 364.64C933.32 344.82 932.66 321.67 908.28 308.3 879.16 293.78 851.74 299.29 839.6 328.79V328.88C840.27 329.64 850.32 340.36 878.49 329.54 878.59 329.54 878.68 329.64 878.59 329.64 873.18 337.51 867.87 352.69 874.04 364.74 874.04 364.83 874.13 364.83 874.23 364.74 911.6 338.56 926.4 374.89 894.15 395.09 894.05 395.09 894.05 395.18 894.15 395.28 906.95 405.05 926.3 403.44 927.63 403.25L927.72 403.15C929.24 388.07 930.76 369.86 931.42 364.64Z"
        fill="#17151c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M968.22 464.62C954.65 443.75 942.32 434.64 925.25 428L925.15 427.91 926.39 415.29C926.39 415.2 926.39 415.2 926.29 415.2H859.8C859.7 415.2 859.7 415.2 859.7 415.29L858.28 426.68C858.28 426.77 858.19 426.77 858.19 426.77 814.84 424.21 778.32 464.81 753.28 492.51 753.18 492.6 753.09 492.51 753.09 492.51 752.8 491.27 751.19 483.78 748.53 472.87 748.53 472.78 748.44 472.78 748.44 472.78L686.69 493.27 686.59 493.36C686.97 595.33 738.1 604.34 799.19 560.33 807.63 554.54 815.5 548.09 822.71 540.79 822.8 540.69 822.8 540.79 822.9 540.79L830.96 566.97 839.31 594.1 839.4 594.19 895.08 605.1 941.09 594.38H941.18L954.56 611.08C954.75 611.83 963 646.55 970.59 640.48 977.51 634.88 982.73 627.39 986.34 618.76 1006.35 571.62 983.02 487.57 968.22 464.62Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M986.24 618.85C954.94 596.09 951.43 541.64 951.05 534.24 951.05 534.15 950.86 534.15 950.86 534.24 948.68 547.9 943.27 581.86 941.28 594.1V594.19L954.56 611.46C954.75 611.93 967.93 644.47 974.57 637.16 981.97 629.86 986.05 619.9 986.43 619.04 986.24 618.95 986.24 618.95 986.24 618.85Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1048.47 886.54C1022.67 831.24 1015.27 841.95 993.35 698.63 985.67 648.45 966.7 617.05 941.18 594.1 941.18 594.1 941.18 594.1 941.09 594.1H909.88 839.31C839.31 594.1 839.21 594.1 839.21 594.19 838.74 597.42 822.14 708.21 836.65 843.76 839.78 873.26 847.18 908.07 852.78 939.47 853.82 945.16 854.77 950.18 855.53 955.69 855.53 955.69 855.53 955.78 855.62 955.78L944.6 955.88C944.69 955.88 944.69 955.88 944.69 955.78 943.46 924.19 937.77 891.09 925.15 842.14 923.07 831.43 921.26 811.22 921.64 811.22 929.42 836.45 938.81 863.3 949.82 890.14 958.73 911.96 968.79 933.96 980.07 956.44 980.07 956.44 980.07 956.54 980.17 956.54 982.54 956.54 1066.96 956.16 1070.47 956.16 1070.57 956.16 1070.57 956.07 1070.57 956.07 1066.2 931.78 1058.71 908.92 1048.47 886.54Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1050.37 1031C1037.38 1031 1026.28 1047.7 1018.79 1045.89 1011.2 1044.09 1025.33 1029.2 1025.33 1017.15 1025.33 1006.53 1013.19 1006.05 997.82 1005.96 997.73 1005.96 997.73 1006.05 997.73 1006.05 999.15 1015.16 998.11 1022.18 993.46 1026.92 979.42 1041.72 966.33 1045.13 956.94 1059.17 956.18 1060.41 955.14 1061.73 954.66 1063.25 954.66 1063.35 954.66 1063.35 954.76 1063.35 958.93 1063.54 1064.51 1067.33 1066.69 1067.43 1066.69 1067.43 1066.69 1067.43 1066.78 1067.43L1072 1063.25C1072 1063.25 1072 1063.25 1072 1063.16 1073.71 1052.44 1074.94 1041.72 1075.51 1031 1075.51 1030.91 1075.51 1030.91 1075.41 1030.91L1050.37 1031Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M904.11 656.04C913.4 656.04 918.9 648.54 916.44 639.25L911.41 620.85H877.83L882.76 639.25C885.42 648.54 894.9 656.04 904.11 656.04Z"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M964.99 620.94H946.5L951.43 639.44C953.99 648.64 964.14 656.32 971.54 656.32 975.43 656.32 978.27 655.56 980.36 654.24"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M839.31 594.19L941.09 594 950.86 534.24"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M945.07 573.99L958.45 578.26"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M753.28 492.51L760.59 484.44"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M822.81 540.79L830.88 566.78C830.88 566.87 831.07 566.87 831.07 566.78L847.67 518.4C847.67 518.31 847.57 518.21 847.48 518.31L822.81 540.79C822.81 540.69 822.81 540.69 822.81 540.79Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M822.81 540.88L847.48 518.31"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M858.29 426.68C858.29 426.68 851.56 426.39 846.53 427.06"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M937.59 700.62C933.13 740.27 918.9 762.75 921.75 811.13"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M675.5 373.18C684.99 384.56 703.01 412.92 703.01 412.92"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M665.91 382.76C670.66 389.4 683.94 409.13 691.62 422.41"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M660.22 396.04C660.22 396.04 674.55 420.61 681.19 431.99"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M910.74 1030.62C897.74 1030.62 886.45 1047.51 879.05 1045.7 866.91 1041.81 911.97 1004.06 860.08 1005.86 859.99 1005.86 859.99 1005.86 859.99 1005.96 860.27 1039.06 819.29 1040.01 808.86 1063.25 808.86 1063.25 808.86 1063.25 808.86 1063.35 808.96 1063.63 809.52 1065.53 809.62 1065.91 809.62 1065.91 809.62 1066 809.71 1066L931.32 1066.95H931.41L934.07 1063.44C936.44 1052.82 938.43 1042 939.95 1031.29 939.95 1031.19 939.95 1031.19 939.86 1031.19 937.86 1031 910.74 1030.62 910.74 1030.62Z"
        fill="#1457a3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M712.31 405.14C714.58 409.32 718.66 416.43 718.66 416.43"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M850.88 1028.72L859.51 1036.5"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M841.5 1036.5L848.14 1045.99"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M991.85 1029.2L1000 1036.5"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M982.73 1036.69L988.61 1045.99"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M951.33 1076.91H1069.14C1069.14 1076.91 1069.24 1076.91 1069.24 1076.82 1069.43 1076.25 1070.66 1071.41 1071.99 1063.35 1071.99 1063.25 1071.99 1063.25 1071.9 1063.25L954.65 1063.35C954.65 1063.35 954.56 1063.35 954.56 1063.44 952.66 1067.62 951.43 1072.17 951.14 1076.82 951.24 1076.82 951.33 1076.91 951.33 1076.91Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M808.77 1063.35C806.87 1067.62 805.73 1072.26 805.54 1076.82 805.54 1076.91 805.54 1076.91 805.64 1076.91H930.37C930.37 1076.91 930.47 1076.91 930.47 1076.82 930.66 1076.25 931.89 1071.79 933.88 1063.35 933.88 1063.25 933.88 1063.25 933.79 1063.25 929.8 1062.97 811.8 1063.35 808.77 1063.35 808.77 1063.25 808.77 1063.25 808.77 1063.35Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M921.75 811.22C925.54 823.36 930.29 838.63 935.41 852.29 939.96 864.62 945.27 879.14 949.73 889.67 949.73 889.76 949.92 889.76 949.92 889.67 949.26 879.33 939.68 729.17 937.59 700.81 937.59 700.71 937.4 700.71 937.4 700.81 933.89 736.48 920.52 768.92 921.75 811.22Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
    </g>
  </svg>
);

export const Hero = () => (
  <div className="hero">
    <div className="heroContainer">
      <div className="text">
        <div className="title">
          <div className="vtitle">SELF</div>
          <div className="v2title">
            <Text
              css={{
                textGradient: '45deg, #fa203c -20%, $yellow600 100%',
                margin: 0,
              }}
            >
              Discovery
            </Text>
            <Text
              css={{
                textGradient: '45deg, #557008 -20%, #a9e504 100%',
                margin: 0,
              }}
            >
              Reflection
            </Text>
            <Text
              css={{
                textGradient: '45deg, #1457a3 -20%, $purple600 100%',
                margin: 0,
              }}
            >
              Improvement
            </Text>
          </div>
        </div>
        <Text className="subtitle">
          Experience a new way of understanding yourself with our innovative,
          open-ended Meyers Briggs type tests, analyzed by AI technology for a
          truly personalized approach.
        </Text>
      </div>
      <div className="illus">
        <Illustration />
      </div>
    </div>
  </div>
);
