/* eslint-disable max-len */
import React from 'react';
import { Text } from '@nextui-org/react';

import { ContactUs } from '../../components/contact-us/contact-us';
import { Hero } from '../../components/hero/hero';
import { AboutUs as abu } from '../../content/content';
import './home.scss';

const AIllustration = () => (
  <svg viewBox="0 0 1627 1227" version="1.1">
    <g>
      <path
        d="M1031.99 272.39C1076.61 305.14 1098.63 370.09 1139.86 433.91 1181.08 497.72 1242.08 560.41 1227.96 607.85 1213.84 655.29 1125.17 686.92 1070.39 746.22 1015.61 805.51 994.72 891.92 941.63 941.05 888.54 989.62 803.27 1000.35 747.92 960.26 692.01 920.72 666.6 830.36 608.99 766.55 551.95 702.16 463.29 664.33 422.06 599.38 380.27 533.87 386.48 441.25 439 388.16 491.52 335.08 590.35 320.96 666.6 302.89 743.4 284.25 797.05 261.66 858.05 250.36 919.04 239.63 986.81 240.2 1031.99 272.39Z"
        fill="#dbebf6"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M805.17 911.22L765.27 1045.48"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M766.93 889.96L718.02 954.7"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M874.11 322.87C900.87 304.05 929.09 294.66 959.39 289.13"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M902.67 211.31C894.16 230.82 875.38 276.35 875.38 276.35"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M813.41 217.76C822.04 234.17 832.99 269.52 834.65 285.04"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M694.47 451.93H342.35C318.5 451.93 299.22 471.2 299.22 495.05V825.88C299.22 849.73 318.5 869.01 342.35 869.01H694.47C718.32 869.01 737.6 849.73 737.6 825.88V495.05C737.6 471.2 718.24 451.93 694.47 451.93Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M346.38 528.5C356.17 528.5 364.11 520.56 364.11 510.77 364.11 500.97 356.17 493.04 346.38 493.04 336.58 493.04 328.65 500.97 328.65 510.77 328.65 520.56 336.58 528.5 346.38 528.5Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M397.86 528.5C407.66 528.5 415.59 520.56 415.59 510.77 415.59 500.97 407.66 493.04 397.86 493.04 388.07 493.04 380.13 500.97 380.13 510.77 380.13 520.56 388.07 528.5 397.86 528.5Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M449.43 528.5C459.22 528.5 467.16 520.56 467.16 510.77 467.16 500.97 459.22 493.04 449.43 493.04 439.63 493.04 431.7 500.97 431.7 510.77 431.7 520.56 439.63 528.5 449.43 528.5Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1287.71 367.11H935.14C911.26 367.11 891.96 386.41 891.96 410.29V741.54C891.96 765.41 911.26 784.72 935.14 784.72H1287.71C1311.59 784.72 1330.89 765.41 1330.89 741.54V410.29C1330.89 386.41 1311.51 367.11 1287.71 367.11Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M939.17 443.78C948.98 443.78 956.92 435.83 956.92 426.02 956.92 416.22 948.98 408.27 939.17 408.27 929.37 408.27 921.42 416.22 921.42 426.02 921.42 435.83 929.37 443.78 939.17 443.78Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M990.72 443.78C1000.53 443.78 1008.48 435.83 1008.48 426.02 1008.48 416.22 1000.53 408.27 990.72 408.27 980.92 408.27 972.97 416.22 972.97 426.02 972.97 435.83 980.92 443.78 990.72 443.78Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1042.35 443.78C1052.16 443.78 1060.11 435.83 1060.11 426.02 1060.11 416.22 1052.16 408.27 1042.35 408.27 1032.55 408.27 1024.6 416.22 1024.6 426.02 1024.6 435.83 1032.55 443.78 1042.35 443.78Z"
        fill="#e9f3f2"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M714.08 581.87C714.63 569.16 702.03 568.61 702.03 568.61 698.96 560.39 693.7 559.73 689.86 560.61 689.86 560.5 689.76 560.39 689.76 560.28 689.1 559.07 687.89 558.64 686.36 558.86 688.77 549.32 694.14 523.79 684.39 522.91 677.7 522.37 664.66 558.86 655.35 587.57 655.24 587.78 655.13 588.11 655.02 588.33 653.59 592.5 650.96 600.82 648.55 608.93 643.73 623.4 637.81 645.97 633.21 665.48L701.26 685.97C702.36 675.34 703.01 666.57 704.11 662.19 718.79 650.68 714.41 633.15 713.1 619.23 713.97 619.01 714.96 618.47 715.62 617.81 720.11 614.08 724.6 604.88 724.38 593.92 724.05 580.66 714.08 581.87 714.08 581.87Z"
        fill="#d9957d"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M686.14 604.66C694.91 595.89 698.63 586.58 702.58 570.25"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M702.91 610.69C707.29 605.21 712.77 592.06 715.18 582.85"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M712.88 619.45C707.51 620.44 703.12 618.36 703.12 609.59 703.12 609.59 698.63 615.07 693.15 613.97 688.55 613.1 686.91 609.7 685.37 605.32"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M712.99 619.01C712.44 619.34 711.89 619.56 711.34 619.67 705.97 620.66 702.47 619.56 701.59 609.92 699.62 613.32 695.56 615.07 691.73 614.3L690.96 614.85 689.43 614.74C690.85 619.12 687.34 617.37 691.4 619.78 693.04 620.77 697.54 622.3 699.95 619.45 703.23 629.75 713.53 624.06 713.42 622.85 713.1 621.54 712.99 620.33 712.99 619.01Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M658.1 583.29C663.58 573.43 685.27 550.97 689.88 559.18 695.35 569.05 680.01 594.25 680.01 594.25 680.01 594.25 696.34 620.55 692.07 644.66"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M543.58 589.98C542.48 585.81 532.95 586.36 532.95 586.36 532.62 584.5 532.4 582.52 531.96 580.55 528.56 563.46 524.95 550.2 514.54 539.9 505.77 531.13 483.42 523.79 456.02 533.76 423.37 548.45 420.41 570.47 421.39 590.52 422.05 602.91 427.53 663.72 427.97 668.11H503.14L501.17 652.66C526.37 655.83 534.59 645.09 538.32 635.78 542.48 625.26 539.85 612.44 536.56 600.71 536.34 600.82 544.67 595.24 543.58 589.98Z"
        fill="#d9957d"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M426.43 653.2V652.98C425.67 644.33 421.83 600.82 421.28 590.41 420.3 570.03 423.59 547.02 456.13 532.45 464.46 529.49 472.9 527.95 481.11 527.95 498.1 527.95 510.48 534.2 515.85 539.68 523.85 547.68 527.03 557.43 529.33 566.74V566.85L529.22 566.96C529.22 566.96 525.06 571.02 515.63 571.02 513.22 571.02 510.59 570.69 507.96 570.25 508.51 574.85 507.85 578.25 505.88 580.44 504.35 582.2 502.04 583.07 498.98 583.07 496.02 583.07 493.17 582.2 492.29 581.87 492.51 583.95 493.39 596.11 486.81 604.33L486.7 604.44 486.48 604.33C486.37 604.22 473.88 596.11 462.6 596.11 456.9 596.11 452.62 598.19 449.45 602.14 437.5 617.81 462.71 637.75 463.03 637.97L463.25 638.08 463.14 638.3C451.64 653.09 426.76 653.2 426.43 653.2Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M501.06 652.66C501.06 652.66 492.29 651.23 479.25 645.09L500.84 666.9 502.81 666.46 501.06 652.66Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M529.66 850.67H370.29V867.98H529.66V850.67Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M633.43 664.05C627.95 686.63 623.79 705.91 623.79 705.91 548.29 679.5 504.46 677.97 504.46 677.97L503.8 677.75 477.61 669.42 428.74 677.97 407.04 677.75H401.67 399.81C379.97 677.75 353.45 694.95 355.32 728.7 356.19 745.91 359.37 762.67 364.08 778.56 362.66 802.34 358.06 809.03 358.17 829.74 358.17 844.31 370.33 850.78 370.33 850.78L402.22 854.28C409.78 864.15 417.78 872.47 425.45 878.94 452.51 901.51 513.33 892.75 540.18 887.38L547.63 826.01C542.48 825.13 537.22 824.26 532.07 823.49L538.21 774.18C538.21 774.18 621.93 798.73 656.55 786.01 685.92 775.17 697.21 726.4 701.26 685.2L633.43 664.05Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M504.47 677.97L502.93 664.82H478.5 477.62 427.65C427.65 664.82 428.75 676.54 428.86 677.97L463.81 693.2 478.17 671.72 490.22 693.2 504.47 677.97Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M543.81 745.14L540.3 773.19"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M477.83 723.34C482.13 723.34 485.61 719.85 485.61 715.55 485.61 711.25 482.13 707.77 477.83 707.77 473.53 707.77 470.05 711.25 470.05 715.55 470.05 719.85 473.53 723.34 477.83 723.34Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M680 710.62C684.31 710.62 687.78 707.14 687.78 702.84 687.78 698.54 684.31 695.06 680 695.06 675.71 695.06 672.22 698.54 672.22 702.84 672.22 707.14 675.71 710.62 680 710.62Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M622.03 706.57L618.53 723.34"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M501.07 653.09C496.03 652.22 489.02 650.35 483.32 646.52"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M529.22 614.63C527.47 615.73 521 616.6 518.26 611.56"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M518.27 593.04C520.03 593.04 521.45 591.62 521.45 589.87 521.45 588.1 520.03 586.69 518.27 586.69 516.51 586.69 515.1 588.1 515.1 589.87 515.1 591.62 516.51 593.04 518.27 593.04Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M465.24 613.54L475.21 619.23"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M477.83 723.34C482.13 723.34 485.61 719.85 485.61 715.55 485.61 711.25 482.13 707.77 477.83 707.77 473.53 707.77 470.05 711.25 470.05 715.55 470.05 719.85 473.53 723.34 477.83 723.34Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M601.21 841.68C621.27 851.65 639.79 867.1 653.92 885.29 660.28 893.51 674.09 910.72 664.22 912.91 656 914.77 635.84 893.62 635.84 893.62 635.84 893.62 648.33 924.96 641.21 930.88 635.73 935.48 628.5 928.14 628.5 928.14 622.14 941.84 612.17 930 612.17 930 592.45 945.67 580.28 898.12 580.28 898.12 570.86 894.83 562.09 890.23 553.88 884.42 553.88 884.42 539.74 883.87 539.08 883.32L547.41 826.01C567.35 829.63 587.41 834.78 601.21 841.68Z"
        fill="#d9957d"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M637.06 895.38C634.1 887.71 633.77 886.5 629.06 876.75"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M612.07 882.55C619.74 898.88 625.22 916.96 628.4 928.14"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M594.76 888.03C600.46 901.18 606.38 919.05 610.1 930.88"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M623.79 705.8C620.17 704.6 616.78 703.39 613.27 702.19L615.79 743.28 623.79 705.8Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M542.48 742.62L538.21 774.18C538.21 774.18 568.12 782.84 599.46 787.33 599.24 787.11 557.27 758.95 542.48 742.62Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M376.36 814.4C373.69 853.91 378.16 851.63 378.83 851.73L399.17 854.25 376.36 814.4C377.34 806.29 376.25 814.29 376.36 814.4Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M444.3 757.74C443.09 764.21 471.58 814.4 471.58 814.4L487.47 815.82 445.39 757.2C445.28 757.2 444.62 756.98 444.3 757.74Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M444.3 757.74L470.81 815.05"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M398.82 852.86C393.12 844.86 386.88 835.11 382.38 826.23 378.88 819.33 375.92 812.09 373.51 804.75"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M530.77 821.41C498.99 816.59 481.56 816.04 469.73 815.05 461.95 814.4 451.21 814.07 451.21 814.07"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M517.82 877.19C522.13 877.19 525.6 873.7 525.6 869.41 525.6 865.1 522.13 861.63 517.82 861.63 513.53 861.63 510.04 865.1 510.04 869.41 510.04 873.7 513.53 877.19 517.82 877.19Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1156.65 608.92C1166.06 608.92 1170.77 605.92 1170.77 599.93 1170.77 592.87 1171.52 581.64 1172.37 568.48 1174.3 539.27 1176.76 503 1172.91 486.42 1167.13 462.14 1141.88 453.47 1120.27 453.47 1112.04 453.47 1103.58 454.65 1095.99 457 1072.56 464.06 1067.96 482.68 1062.72 504.18 1062.08 506.96 1061.33 509.74 1060.68 512.52 1059.51 512.74 1051.7 514.24 1051.06 517.45 1050.2 521.62 1055.76 526.97 1056.51 527.72 1052.55 550.61 1056.3 564.95 1068.39 572.76 1074.16 576.5 1080.05 578.32 1086.47 578.32 1091.71 578.32 1097.49 577.04 1101.66 575.86L1100.7 594.58 1100.8 594.69C1101.34 594.9 1136.54 608.92 1156.65 608.92Z"
        fill="#e59763"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1017.36 660.38C1017.68 659.63 1050.09 586.45 1052.98 580.78 1054.37 578 1055.87 575.11 1056.73 572.11 1057.05 570.72 1057.47 569.01 1056.83 567.73 1055.87 565.59 1052.98 566.34 1051.27 566.87 1045.49 568.8 1040.68 572.97 1035.97 576.71 1034.15 578.1 1032.44 579.6 1030.52 580.89 1029.02 581.85 1027.09 583.03 1025.49 581.74 1023.35 580.03 1024.74 576.93 1025.91 575.11 1027.73 572.33 1030.3 570.08 1032.87 568.16 1036.83 565.27 1040.79 563.34 1044.32 561.63 1049.45 559.17 1053.09 557.46 1053.09 554.14 1053.09 546.44 1037.47 548.79 1033.08 549.33 1024.31 550.5 1015.54 552.86 1007.83 557.35 999.92 561.95 993.28 568.69 989.22 576.93 979.27 596.93 977.45 600.36 964.93 623.36L964.61 627.42 1017.36 660.8V660.38 660.38Z"
        fill="#e59763"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1224.8 799.67C1232.39 799.56 1252.51 793.57 1259.35 765.54 1262.67 750.78 1260.32 729.59 1252.61 704.35 1246.19 683.06 1235.92 659.52 1224.69 639.62 1204.58 604.32 1174.41 593.08 1171.84 592.23L1171.73 583.45 1101.12 583.56 1100.38 591.58C1061.65 593.3 1034.69 620.9 1034.47 621.11 1034.37 621.22 1021.64 635.02 1013.18 645.61L965.15 623.04C950.17 650.32 919.15 716.65 956.05 742.86 964.08 748.53 973.6 751.42 984.19 751.42 1018.43 751.42 1055.12 721.46 1058.76 718.47L1069.99 784.26 1191.52 784.37C1196.98 795.17 1213.03 799.77 1224.8 799.67Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1198.59 736.76C1209.6 744.57 1211.21 760.08 1214.53 772.92 1217.41 784.05 1221.48 790.89 1224.69 799.67 1213.03 799.77 1196.87 795.17 1191.42 784.37L1198.16 736.55 1198.59 736.76Z"
        fill="#2d2d2d"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1069.99 784.26L1058.76 718.47 1074.38 705.52 1108.08 784.26H1069.99Z"
        fill="#2d2d2d"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1173.55 485.35C1167.67 460.85 1142.21 452.08 1120.38 452.08 1112.04 452.08 1103.48 453.26 1095.88 455.61 1077.59 461.17 1071.38 474.33 1067.42 485.99 1067.42 485.99 1071.7 489.31 1078.23 489.31 1082.51 489.31 1086.9 487.81 1090.85 484.92 1089.25 495.3 1090.1 502.68 1093.42 506.53 1095.35 508.89 1098.13 510.06 1101.87 510.06 1107.33 510.06 1112.79 507.39 1114.5 506.53 1113.21 509.74 1107.22 526.43 1118.03 533.28L1118.13 533.39 1118.24 533.28C1118.35 533.17 1132.04 521.94 1143.81 521.94 1148.2 521.94 1151.94 523.54 1154.62 526.75 1156.97 529.53 1158.04 532.96 1157.72 536.6 1156.65 548.47 1141.78 560.88 1141.56 560.99L1141.35 561.2 1141.56 561.42C1151.19 569.01 1172.7 569.55 1172.7 569.55 1172.7 569.55 1179.22 504.5 1173.55 485.35Z"
        fill="#010005"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1066.14 538.63L1076.41 538.95"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1073.52 519.37C1071.87 519.37 1070.53 518.03 1070.53 516.38 1070.53 514.72 1071.87 513.38 1073.52 513.38 1075.18 513.38 1076.52 514.72 1076.52 516.38 1076.52 518.03 1075.18 519.37 1073.52 519.37Z"
        fill="#010005"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1058.44 718.79C1066.03 712.9 1072.77 706.16 1079.62 699.53"
        stroke="#2a2a2a"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1013.83 644.76L992.96 670.54"
        stroke="#2a2a2a"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1191.95 782.87L1202.65 707.98"
        stroke="#2a2a2a"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1100.8 576.07C1109.36 572.65 1115.78 569.65 1115.78 569.65"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1129.58 549.01L1138.46 542.16"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M844.02 316.75L694.7 353.32 737.49 528.02 886.81 491.45 844.02 316.75Z"
        fill="#ffffff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M731.25 378.66L762.1 371.11C763.36 370.81 764.68 371.01 765.78 371.68 766.88 372.35 767.67 373.43 767.99 374.68V374.68C768.29 375.93 768.08 377.25 767.41 378.35 766.74 379.45 765.67 380.25 764.42 380.56L733.56 388.12C732.31 388.42 730.99 388.21 729.89 387.54 728.78 386.87 727.99 385.8 727.68 384.55V384.55C727.38 383.3 727.58 381.98 728.25 380.87 728.92 379.77 730 378.98 731.25 378.66V378.66Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M781.02 366.47L791.97 363.79C793.22 363.5 794.54 363.7 795.64 364.37 796.75 365.04 797.54 366.11 797.85 367.36V367.36C798.15 368.61 797.95 369.93 797.28 371.04 796.61 372.14 795.53 372.93 794.28 373.25L783.33 375.93C782.08 376.23 780.76 376.02 779.66 375.35 778.55 374.68 777.76 373.61 777.45 372.36V372.36C777.15 371.11 777.36 369.79 778.02 368.68 778.69 367.58 779.77 366.79 781.02 366.47V366.47Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M810.38 359.28L821.33 356.6C822.58 356.3 823.91 356.51 825.01 357.18 826.11 357.84 826.9 358.92 827.22 360.17V360.17C827.51 361.42 827.31 362.74 826.64 363.85 825.97 364.95 824.9 365.74 823.65 366.06L812.7 368.74C811.45 369.03 810.13 368.83 809.02 368.16 807.92 367.49 807.13 366.41 806.81 365.17 806.52 363.92 806.72 362.59 807.39 361.49 808.06 360.39 809.14 359.6 810.38 359.28Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M749.71 454.07L780.57 446.51C781.82 446.21 783.15 446.42 784.25 447.09 785.35 447.75 786.14 448.83 786.46 450.08V450.08C786.75 451.33 786.55 452.65 785.88 453.76 785.21 454.86 784.14 455.65 782.89 455.97L752.03 463.52C750.78 463.82 749.46 463.62 748.35 462.95 747.25 462.28 746.46 461.2 746.14 459.95V459.95C745.85 458.7 746.05 457.38 746.72 456.28 747.39 455.17 748.47 454.38 749.71 454.07Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M799.49 441.88L839.8 432C841.05 431.71 842.37 431.91 843.48 432.58 844.58 433.25 845.37 434.33 845.69 435.57V435.57C845.98 436.82 845.78 438.15 845.11 439.25 844.44 440.35 843.36 441.14 842.12 441.46L801.8 451.33C800.55 451.63 799.23 451.43 798.13 450.76 797.02 450.09 796.23 449.01 795.92 447.76V447.76C795.62 446.51 795.82 445.19 796.49 444.09 797.16 442.98 798.24 442.19 799.49 441.88V441.88Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M743.56 428.93L791.34 417.23C792.59 416.93 793.91 417.14 795.01 417.81 796.12 418.48 796.91 419.55 797.22 420.8 797.52 422.05 797.32 423.37 796.65 424.48 795.98 425.58 794.9 426.37 793.65 426.69L745.87 438.39C744.62 438.68 743.3 438.48 742.2 437.81 741.1 437.14 740.3 436.07 739.99 434.82V434.82C739.69 433.57 739.9 432.25 740.56 431.14 741.23 430.04 742.31 429.25 743.56 428.93Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M767.26 396.48L798.12 388.93C799.37 388.63 800.7 388.83 801.8 389.5 802.9 390.17 803.69 391.25 804.01 392.5V392.5C804.31 393.75 804.1 395.07 803.43 396.17 802.76 397.27 801.69 398.07 800.44 398.38L769.58 405.94C768.33 406.24 767.01 406.03 765.9 405.36 764.8 404.69 764.01 403.62 763.69 402.37V402.37C763.4 401.12 763.6 399.8 764.27 398.69 764.94 397.59 766.02 396.8 767.26 396.48V396.48Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M737.4 403.8L748.35 401.12C749.6 400.82 750.92 401.02 752.03 401.69 753.13 402.36 753.92 403.44 754.24 404.69 754.53 405.94 754.33 407.26 753.66 408.36 752.99 409.46 751.91 410.26 750.67 410.57L739.72 413.25C738.47 413.55 737.15 413.35 736.04 412.68 734.94 412.01 734.15 410.93 733.83 409.68V409.68C733.54 408.43 733.74 407.11 734.41 406.01 735.08 404.91 736.16 404.11 737.4 403.8V403.8Z"
        fill="#77a9e3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M816.54 384.42L827.49 381.73C828.74 381.44 830.06 381.64 831.16 382.31 832.27 382.98 833.06 384.06 833.37 385.3V385.3C833.67 386.56 833.47 387.88 832.8 388.98 832.13 390.08 831.05 390.87 829.8 391.19L818.85 393.87C817.6 394.17 816.28 393.96 815.18 393.29 814.08 392.63 813.28 391.55 812.97 390.3V390.3C812.67 389.05 812.88 387.73 813.55 386.63 814.21 385.52 815.29 384.73 816.54 384.42Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M785.73 471.89L845.96 457.14C847.21 456.84 848.53 457.05 849.63 457.71 850.74 458.38 851.53 459.46 851.84 460.71V460.71C852.14 461.96 851.93 463.28 851.27 464.38 850.6 465.49 849.52 466.28 848.27 466.59L788.05 481.34C786.8 481.64 785.48 481.44 784.37 480.77 783.27 480.1 782.48 479.02 782.16 477.77V477.77C781.87 476.52 782.07 475.2 782.74 474.1 783.41 472.99 784.49 472.2 785.73 471.89V471.89Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M755.87 479.2L766.82 476.52C768.07 476.22 769.39 476.43 770.5 477.1 771.6 477.77 772.39 478.84 772.71 480.09 773 481.34 772.8 482.66 772.13 483.77 771.46 484.87 770.38 485.66 769.14 485.98L758.19 488.66C756.93 488.95 755.61 488.75 754.51 488.08 753.41 487.41 752.62 486.33 752.3 485.09V485.09C752 483.84 752.21 482.51 752.88 481.41 753.55 480.31 754.62 479.52 755.87 479.2Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M812.94 867.71L919.06 971.29 841.83 1021.47 812.94 867.71Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M812.94 867.71L952.07 978.98 874.83 1029.16 812.94 867.71Z"
        fill="#61eeff"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M680.69 378.63L636.01 265.25 573.39 307.78 680.69 378.63Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M680.69 378.63L640.69 237.79 578.08 280.33 680.69 378.63Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M218.48 627.94V642.76"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M218.48 684.82V699.64"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M254.33 663.79H239.51"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M197.45 663.79H182.63"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M193.15 638.45L203.58 648.89"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M233.38 678.69L243.81 689.12"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M243.81 638.45L233.38 648.89"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M203.58 678.69L193.15 689.12"
        stroke="#61eeff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M1409.48 547.81L1396.42 528.58 1373.16 528.34 1387.4 510.07 1380.52 487.99 1402.36 495.82 1421.11 482.29 1420.4 505.56 1439.15 519.32 1416.83 525.73 1409.48 547.81Z"
        fill="#fa203c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1491.67 673.22C1474.1 699.82 1402.53 770.84 1374.76 736.37 1346.98 701.9 1377.45 617.8 1391.53 616.22 1409.41 614.26 1383.32 674.33 1347.45 678.33 1311.59 682.33 1355.49 599.63 1332.69 586.29 1314.16 575.47 1279.46 623.85 1241.97 615.87"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M138.89 498.3C158.27 499.22 179.13 499.41 221.96 476.15 296.72 435.54 333.91 429.63 339.18 448.46 343.79 465.07 304.1 490.92 287.49 507.53 252.32 542.7 261.09 566.69 301.33 560.14 341.02 553.68 365.02 530.61 398.24 563.83"
        stroke="#ffffff"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
  </svg>
);

const AboutUs = () => (
  <section id="About" className="aboutUs">
    <div className="container">
      <div className="illus">
        <AIllustration />
      </div>
      <div className="textCont">
        <div className="text">
          <div className="title">About Us</div>
          <div className="subtitle">Welcome to EQLZE </div>
          <div className="subsubtitle">
            Where we empower individuals to live their best lives through
            self-discovery, self-reflection, and self-improvement.
          </div>
          <div className="para">{abu}</div>
        </div>
      </div>
    </div>
  </section>
);

const HIllustration = () => (
  <svg viewBox="0 0 1600 1200" version="1.1">
    <g>
      <path
        d="M570.1 672.6V763.5C570.1 776.5 559.6 787 546.6 787H531.3C518.3 787 507.8 797.5 507.8 810.5V846.1C507.8 859.1 518.3 869.6 531.3 869.6H900.7C913.7 869.6 924.2 880.1 924.2 893.1V922.8C924.2 935.8 934.7 946.3 947.7 946.3H966.5C979.5 946.3 990 935.8 990 922.8V857.5C990 844.5 1000.5 834 1013.5 834H1316.5C1329.5 834 1340 823.5 1340 810.5V703.9"
        stroke="#231f20"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M335.01 234.25C301.43 235.1 274.96 262.92 275.72 296.42 276.15 314.68 284.86 331.85 299.32 343.02 308.71 350.2 314.2 361.28 314.29 373.04L317.5 379.81H353.36L358.69 373.04C358.69 361.37 364.1 350.29 373.41 343.19 400.05 322.8 405.21 284.74 384.82 258.02 372.98 242.63 354.46 233.75 335.01 234.25Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M314.29 373.12V388.6C314.29 400.87 324.18 410.76 336.45 410.76 348.71 410.76 358.61 400.87 358.61 388.6V373.12H314.29Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M314.29 373.04H358.69"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M314.29 385.3H358.61"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M329.01 373.04V355.36C329.01 343.52 324.35 332.11 315.98 323.73 309.64 317.39 306.17 308.85 306.17 299.88 306.17 293.54 311.33 288.47 317.59 288.47 323.93 288.47 329.01 293.62 329.01 299.88V306.48C329.01 310.54 332.3 313.84 336.36 313.84 340.42 313.84 343.72 310.54 343.72 306.48V299.88C343.72 293.54 348.88 288.47 355.14 288.47H355.31C361.65 288.47 366.73 293.62 366.73 299.88 366.73 308.85 363.17 317.47 356.83 323.82 348.46 332.28 343.72 343.61 343.72 355.53L343.81 373.12"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M707.21 418.08L645.1 398.65V367.99H624.06L515.35 464.97 624.06 574.38H645.1V543.72L707.21 524.29V418.08Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M652.21 400.85L645.1 398.65V543.72L669.44 536.11 652.21 400.85Z"
        fill="#000000"
        opacity="0.3"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M581.98 553.34H474.77V626.48H581.98V553.34Z"
        fill="#f0e9f3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M552.92 553.34H445.72V626.48H552.92V553.34Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M604.02 612.45H709.22V681.58H604.02L538.69 650.52 604.02 612.45Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M604.02 612.45H354.55V681.58H604.02V612.45Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M624.06 367.99H374.58V574.38H624.06V367.99Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M594 398.05H404.64V544.32H594V398.05Z"
        fill="#f0e9f3"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M588.99 627.48H502.83V645.51H588.99V627.48Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M645.1 398.65V543.72"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M431.69 427.1H499.82"
        stroke="#41cdfb"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M431.69 447.14H463.75"
        stroke="#41cdfb"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M431.69 477.2H481.79"
        stroke="#41cdfb"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M1415.57 248.64L1457.78 670.72C1461.03 703.28 1431.09 729.73 1390.7 729.84L781.22 731.43C740.98 731.53 705.76 705.25 702.51 672.69L660.3 250.61C657.04 218.05 686.99 191.6 727.23 191.5L1336.7 189.91C1376.94 189.8 1412.31 216.08 1415.57 248.64Z"
        fill="#fff5f5"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M769.43 281.91H984.56C998.24 281.91 1010.61 293.05 1012.12 306.73 1013.63 320.41 1003.72 331.56 990.04 331.56H774.91C761.23 331.56 748.86 320.41 747.34 306.73 745.83 293.05 755.75 281.91 769.43 281.91Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M780.76 384.51H906.53C920.21 384.51 932.58 395.65 934.09 409.33 935.6 423.01 925.69 434.16 912.01 434.16H786.24C772.56 434.16 760.19 423.01 758.68 409.33 757.17 395.65 767.08 384.51 780.76 384.51Z"
        fill="#9fb65f"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M989.27 384.51H1028.98C1042.66 384.51 1055.04 395.65 1056.55 409.33 1058.06 423.01 1048.15 434.16 1034.47 434.16H994.75C981.07 434.16 968.7 423.01 967.19 409.33 965.68 395.65 975.59 384.51 989.27 384.51Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M792.09 487.11H822.98C836.66 487.11 849.03 498.25 850.54 511.93 852.05 525.61 842.14 536.76 828.46 536.76H797.57C783.89 536.76 771.52 525.61 770.01 511.93 768.5 498.25 778.41 487.11 792.09 487.11Z"
        fill="#9fb65f"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M905.72 487.11H1091.07C1104.75 487.11 1117.12 498.25 1118.63 511.93 1120.14 525.61 1110.23 536.76 1096.55 536.76H911.21C897.53 536.76 885.15 525.61 883.64 511.93 882.13 498.25 892.04 487.11 905.72 487.11Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1173.81 487.11H1332.67C1346.35 487.11 1358.73 498.25 1360.24 511.93 1361.75 525.61 1351.84 536.76 1338.16 536.76H1179.29C1165.61 536.76 1153.24 525.61 1151.73 511.93 1150.22 498.25 1160.13 487.11 1173.81 487.11Z"
        fill="#b8c88a"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M803.42 589.71H902.71C916.39 589.71 928.77 600.85 930.28 614.53 931.79 628.21 921.87 639.36 908.19 639.36H808.9C795.22 639.36 782.85 628.21 781.34 614.53 779.83 600.85 789.74 589.71 803.42 589.71Z"
        fill="#000000"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M985.45 589.71H1007.52C1021.2 589.71 1033.57 600.85 1035.08 614.53 1036.59 628.21 1026.68 639.36 1013 639.36H990.94C977.26 639.36 964.88 628.21 963.37 614.53 961.86 600.85 971.77 589.71 985.45 589.71Z"
        fill="#9fb65f"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1090.26 589.71H1221.55C1235.23 589.71 1247.6 600.85 1249.11 614.53 1250.62 628.21 1240.71 639.36 1227.03 639.36H1095.74C1082.06 639.36 1069.69 628.21 1068.18 614.53 1066.67 600.85 1076.58 589.71 1090.26 589.71Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1047.47 478.89L1047.91 472.79C1028.64 452.89 1015.55 440.87 1009 436.98 989.82 425.57 964.08 417.08 953.82 417.08 950.82 417.08 949.14 417.79 948.96 419.29 948.17 425.57 968.77 435.21 971.78 436.53 965.76 439.72 967.18 447.15 967.44 448.21 960.81 454.13 964.52 460.06 965.14 460.94 964.35 461.82 959.31 468.1 966.2 475 970.45 479.25 974.52 480.13 977.08 480.13 978.67 480.13 979.73 479.78 980.09 479.69 986.81 491.01 999.37 494.54 1000.51 494.81 1000.78 495.43 1002.64 499.67 1005.64 505.77L1011.75 507.19 1047.47 478.89Z"
        fill="#e59763"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1220.17 442.46V442.28C1222.83 430.7 1226.8 412.57 1230.7 392.23 1233.97 374.81 1231.76 360.49 1224.15 350.76 1217.34 342 1206.55 337.14 1192.14 336.17 1190.73 336.08 1189.4 336.08 1188.07 336.08 1160.04 336.08 1149.96 355.09 1144.21 371.72 1143.33 374.37 1142.36 377.02 1141.56 379.67 1140.5 379.76 1133.6 380.47 1132.72 383.92 1131.83 387.28 1136.43 392.14 1137.14 392.85 1133.87 403.73 1132.89 410.09 1132.63 413.63 1132.45 416.28 1132.54 422.92 1137.4 428.22 1141.74 432.91 1148.63 435.21 1157.92 435.21 1160.48 435.21 1163.22 435.03 1166.05 434.68L1164.64 442.9 1220.17 442.46Z"
        fill="#e59763"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1177.37 431.85C1177.37 431.85 1171.36 433.79 1167.47 434.41L1166.05 434.68 1164.73 442.55 1169.06 447.85 1177.37 431.85Z"
        fill="#030202"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1254.84 618.43C1274.73 616.31 1288.62 603.49 1291.89 583.95 1294.89 566.17 1284.2 516.3 1263.77 486.32 1244.23 457.67 1220.26 452.27 1217.87 451.83 1218.05 451.04 1218.93 447.59 1220.17 442.28L1220.26 442.1 1164.82 441.93 1163.49 449.27C1151.37 449.89 1139.61 452.45 1128.29 456.87 1110.87 463.77 1080.37 501.97 1078.07 504.8 1076.56 503.12 1062.95 488.18 1047.91 472.7L1047.82 472.61 1005.56 505.6 1005.64 505.77C1016.17 527.09 1043.58 576.61 1070.2 577.58 1070.82 577.58 1071.52 577.58 1072.14 577.58 1089.47 577.58 1105.21 568.91 1115.38 561.66 1125.29 554.59 1131.92 547.42 1133.25 546.01L1150.31 617.37 1254.84 618.43Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1254.84 618.43C1261.29 617.81 1267.57 615.87 1273.14 612.6L1273.41 612.42 1273.14 612.24C1258.37 601.72 1255.54 586.95 1253.24 575.01 1251.65 566.61 1250.33 559.45 1245.11 556.09L1244.93 555.91 1235.29 601.1 1254.84 618.43Z"
        fill="#4f4f4f"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1143.5 534.78L1133.25 546.01 1150.31 617.55 1177.28 618.43 1143.5 534.78Z"
        fill="#4f4f4f"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1299.23 477.65C1301.7 444.4 1284.02 432.38 1266.95 420.62 1253.78 411.6 1241.31 403.11 1238.21 385.6 1231.85 350.05 1221.06 338.29 1192.05 335.02 1190.11 334.84 1188.16 334.66 1186.22 334.66 1163.93 334.66 1153.67 349.52 1149.61 358.54 1149.61 358.54 1151.2 369.15 1166.94 369.15 1168.09 369.15 1169.33 369.06 1169.18 369.21 1168.09 376.76 1169.66 384.94 1174.19 391.88L1174.24 391.97 1174.46 391.89C1174.45 391.88 1183.83 388.43 1192.23 388.43 1199.21 388.43 1203.55 390.73 1205.14 395.33 1209.74 409.03 1188.6 423.27 1188.43 423.45H1188.34V423.53C1188.25 423.8 1181.35 453.07 1191.88 477.56L1191.96 477.65H1299.23V477.65Z"
        fill="#010005"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1247.32 357.57C1226.95 357.57 1210.45 341.06 1210.45 320.69 1210.45 300.33 1226.95 283.82 1247.32 283.82 1267.69 283.82 1284.2 300.33 1284.2 320.69 1284.2 341.06 1267.69 357.57 1247.32 357.57Z"
        fill="#010005"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1190.28 432.73C1184.82 432.73 1180.38 428.3 1180.38 422.83 1180.38 417.35 1184.82 412.92 1190.28 412.92 1195.76 412.92 1200.19 417.35 1200.19 422.83 1200.19 428.3 1195.76 432.73 1190.28 432.73Z"
        fill="#fff5f5"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1146.25 600.57V600.39H1165.26L1235.38 600.39H1235.47C1281.45 636.12 1291.27 668.84 1301.7 703.5 1307.63 723.22 1313.73 743.56 1326.64 765.84 1333.1 777.07 1340.26 785.39 1348.57 795.11 1362.28 811.12 1379.34 831.1 1403.57 874.61 1404.19 875.76 1404.81 876.91 1405.43 878.06L1405.25 887.61 1335.93 918.65 1328.23 914.93C1319.21 900.79 1307.28 885.93 1295.69 871.52 1288.44 862.5 1280.92 853.12 1274.11 843.93L1273.76 843.4C1258.99 823.41 1246.26 806.17 1230.16 772.65 1229.81 777.34 1229.55 782.2 1229.46 787.15 1229.02 811.65 1231.93 825.62 1235.56 843.4 1239.18 861.26 1243.78 883.45 1246.26 924.22V924.93L1239.98 934.3 1170.65 933.33 1163.67 925.19C1161.19 904.85 1157.03 881.86 1152.97 859.58 1150.31 844.63 1147.75 830.4 1145.89 818.19 1138.2 766.46 1130.77 717.65 1146.25 600.57Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1255.1 817.22C1255.01 817.04 1250.59 799.09 1249.71 766.82 1249.44 757.27 1249.44 748.69 1249.44 741.17 1249.44 715.79 1251.48 702.79 1241.93 693.95L1229.9 772.21C1242.46 797.94 1249.44 808.47 1249.44 808.47L1255.1 817.22Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1242.55 683.96C1241.04 711.81 1232.2 739.85 1230.16 772.65"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1240.86 1019.63L1243.43 1015.3C1245.82 993.55 1248.29 960.21 1246.26 925.1V924.93H1163.58L1163.67 925.55C1167.03 953.22 1166.41 969.94 1161.81 977.37 1154.03 989.83 1145.45 996.91 1137.93 1003.1 1132.89 1007.17 1128.56 1010.7 1125.29 1015.21L1125.91 1020.96 1240.86 1019.63Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1240.86 1034.84V1034.67C1240.86 1034.58 1242.1 1027.24 1243.43 1015.3V1015.12H1125.29L1125.2 1015.21C1121.22 1020.61 1119.36 1026.8 1119.36 1034.67V1034.84H1240.86V1034.84Z"
        fill="#250a0f"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1434.79 968.43L1436.12 962.33C1431.08 940.67 1421.7 908.39 1405.43 878.06L1405.34 877.88 1328.06 914.76 1328.14 914.93C1340.97 935.1 1346.01 950.93 1343.97 964.81 1341.94 978.87 1337.43 988.68 1333.45 997.35 1330.62 1003.45 1328.41 1008.93 1327.35 1014.51L1331.95 1018.22 1434.79 968.43Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1440.27 982.49C1440.27 982.32 1439.03 974.54 1436.2 962.42L1436.12 962.24 1327.35 1014.42C1326.11 1021.14 1327.17 1027.68 1330.71 1034.84L1330.8 1035.02 1440.27 982.49Z"
        fill="#250a0f"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1142.36 402.58C1143.77 403.55 1148.19 403.73 1150.93 400.63"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1150.93 388.25C1152.44 388.25 1153.59 387.01 1153.59 385.6 1153.59 384.18 1152.35 382.95 1150.93 382.95 1149.43 382.95 1148.28 384.18 1148.28 385.6 1148.28 387.01 1149.52 388.25 1150.93 388.25Z"
        fill="#171314"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M1165.61 434.85C1171.54 434.06 1176.22 432.73 1176.22 432.73"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1179.67 408.68L1187.63 405.14"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1247.32 547.51L1235.38 600.57 1146.51 600.57"
        stroke="#4f4f4f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1254.84 578.46L1241.4 574.04"
        stroke="#4f4f4f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1133.34 545.83C1139.88 539.55 1146.07 532.48 1146.07 532.48"
        stroke="#4f4f4f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1078.07 505.15V520.54"
        stroke="#4f4f4f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1047.82 472.88L1005.91 505.69"
        stroke="#4f4f4f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1165.5 965.52L1182.32 967.55"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1161.91 977.93L1174.9 987.18"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1341.92 942.05L1360.07 937.48"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1345.35 959.67L1363.6 957.82"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1246.08 925.1H1163.84"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M1328.41 914.85L1405.25 878.15"
        stroke="#250a0f"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M972.31 436.53C977.88 436.27 983.36 436.98 988.67 438.74"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M967.71 448.21C974.52 446.7 984.24 450.24 984.24 450.24"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M965.41 460.94C972.75 459.97 980.71 462.62 980.71 462.62"
        stroke="#030202"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M709.1 761H894.4V1032H709.1L684.9 906.6 709.1 761Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M709.1 761H605V1032H709.1V761Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M688.5 790.3H625.6V817H688.5V790.3Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M657.6 975.7C660.91 975.7 663.6 973.01 663.6 969.7 663.6 966.39 660.91 963.7 657.6 963.7 654.29 963.7 651.6 966.39 651.6 969.7 651.6 973.01 654.29 975.7 657.6 975.7Z"
        fill="#0f2127"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M657.6 999.5C660.91 999.5 663.6 996.81 663.6 993.5 663.6 990.19 660.91 987.5 657.6 987.5 654.29 987.5 651.6 990.19 651.6 993.5 651.6 996.81 654.29 999.5 657.6 999.5Z"
        fill="#0f2127"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M672.8 834.9V849.6"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M657 834.9V849.6"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M641.3 834.9V849.6"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M796 953H863.5"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M796 975H863.5"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M796 997H863.5"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M657.6 946.3C666.27 946.3 673.3 939.27 673.3 930.6 673.3 921.93 666.27 914.9 657.6 914.9 648.93 914.9 641.9 921.93 641.9 930.6 641.9 939.27 648.93 946.3 657.6 946.3Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M1463.92 817.79L1477.94 839.21 1456.18 827.73 1448.7 866.23 1440.65 825.15 1406.2 842.01 1431.32 812.46 1400.01 781.84 1438.33 795.77 1441.26 752.97 1454.68 791.81 1488.57 772.2 1464.87 804.76 1499.15 815.69 1463.92 817.79Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
    <g>
      <path
        d="M443.87 935.28L519.17 896.45C519.26 896.45 519.26 896.45 519.26 896.54L521.63 905.48C523.54 915.41 525.09 923.25 526.19 925.8L521.63 975.49C521.63 975.58 521.63 975.58 521.54 975.58H461.1L452.89 971.66C452.89 971.66 452.89 971.66 452.8 971.57 449.34 961.99 446.33 952.61 443.87 943.31V935.28 935.28Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M327.82 970.29L325.63 965.46C325.63 965.46 325.63 965.46 325.63 965.37 326.82 955.52 328 946.04 329.83 936.93L334.11 924.35 334.2 924.25 417.89 925.53C417.98 925.53 417.98 925.62 417.98 925.62L415.97 936.83C413.79 944.13 412.42 950.6 411.78 955.7L389.63 999.01C389.63 999.01 389.54 999.1 389.54 999.01L327.82 970.29Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M266.29 749.59L272.03 722.88 272.12 722.79 368.02 727.99 373.77 731C374.95 731.09 451.43 737.47 480.42 767.1 503.4 790.71 506.13 823.25 514.97 870.01 517.34 882.59 519.62 895.08 521.63 905.29 521.63 905.29 521.63 905.38 521.54 905.38L443.96 943.22C443.87 943.22 443.87 943.22 443.87 943.12 441.86 935.47 440.04 928.36 438.49 921.7 430.01 884.33 429.1 861.81 429.1 833.92 429.1 833.82 429.1 833.82 429.01 833.82L334.84 834.1C290.26 834.46 256.9 793.26 266.29 749.59Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M429.19 834.01L445.6 792.71C445.6 792.62 445.69 792.62 445.78 792.71 472.22 814.04 467.02 859.8 438.67 921.52 438.67 921.61 438.49 921.61 438.49 921.52 430.1 884.42 429.1 861.72 429.19 834.01Z"
        fill="#000000"
        opacity="0.15"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M227.45 765.27C233.2 740.2 242.31 724.16 242.86 723.16H242.95L296.19 726.62C296.19 726.62 406.77 738.93 440.77 779.31 468.48 812.13 429.65 891.89 415.97 936.74L415.88 936.83H329.92C329.83 936.83 329.83 936.74 329.83 936.74 337.3 898.09 349.61 864.09 364.29 834.74 364.29 834.64 364.29 834.64 364.19 834.64H294.09C240.31 834.64 219.34 800.82 227.45 765.27Z"
        fill="#ff2e58"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M378.69 808.21L364.38 834.55 364.29 834.64H308.59C338.94 834.74 367.57 805.02 378.69 808.21 378.78 808.21 378.69 808.12 378.69 808.21Z"
        fill="#000000"
        opacity="0.15"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M396.28 750.78C414.61 758.16 430.92 767.46 440.95 779.31 460.37 802.37 446.97 848.41 432.47 889.52"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M416.06 936.83H329.92"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M521.63 905.38L443.87 943.4"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M367.93 509.02C367.48 503.55 368.39 496.81 367.2 490.24 365.84 482.86 362.1 475.75 357.45 469.73 348.7 458.25 322.53 454.78 300.38 464.45 280.6 472.92 281.79 490.7 281.79 509.02L279.32 545.76V563.26 566.73L281.24 593.53C281.24 593.53 281.24 593.62 281.33 593.62L336.94 602.28C337.03 602.28 337.03 602.28 337.03 602.19V561.35 553.23C337.03 553.14 337.12 553.14 337.12 553.14 338.58 553.51 354.35 557.25 362.92 549.31 374.68 538.83 368.93 520.6 367.93 509.02Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M320.98 546.76L336.94 561.26C337.03 561.35 337.12 561.26 337.12 561.17V553.23C337.12 553.23 337.12 553.14 337.03 553.14 336.21 552.96 329.28 551.14 321.17 546.58 320.98 546.58 320.89 546.67 320.98 546.76Z"
        fill="#171717"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M360.18 466.36C351.52 454.78 321.71 447.03 299.56 456.7 279.78 465.17 279.32 490.33 279.32 508.66V545.76C279.32 545.85 279.42 545.85 279.42 545.85 287.07 545.3 294.27 541.47 299.47 535.82V535.73C298.47 534.46 282.24 513.22 294.91 502.64 305.94 493.43 319.34 510.3 320.53 511.94 320.53 512.03 320.62 511.94 320.71 511.94L328.37 494.44C328.37 494.44 328.37 494.35 328.46 494.35L367.02 490.24C367.11 490.24 367.11 490.15 367.11 490.15 366.56 481.58 365.01 472.83 360.18 466.36Z"
        fill="#17151c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M412.42 624.79C403.58 613.49 389.45 596.9 377.69 585.05 360.27 567.64 340.58 563.81 337.12 563.26 337.03 563.26 337.03 563.26 337.03 563.35 336.94 564.99 335.75 585.96 313.97 585.96 292.82 585.96 280.05 564.63 279.32 563.35 279.32 563.35 279.23 563.26 279.23 563.35 266.38 566.82 254.53 572.65 243.95 580.49 232.92 588.51 212.14 610.76 195.46 640.02V640.11L234.02 669.19C234.02 669.19 234.02 669.19 234.02 669.28L238.49 731.54C238.49 731.63 238.49 731.63 238.58 731.63H373.58C373.67 731.63 373.67 731.54 373.67 731.54L368.48 682.86C368.48 682.77 368.57 682.77 368.66 682.77 370.94 685.96 373.67 688.79 376.5 691.8H376.59L385.25 683.14 412.42 624.79Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M259 670.65L249.42 689.7 238.67 731.45C238.67 731.54 238.67 731.54 238.76 731.54H268.48C268.57 731.54 268.57 731.54 268.57 731.45L265.74 658.98C265.74 658.89 265.65 658.89 265.56 658.98L259 670.65Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M537.76 551.32C537.76 541.66 496.28 559.07 493.37 560.34 493.28 560.34 493.19 560.34 493.28 560.25 493.64 558.89 495.65 550.32 492.36 549.5 488.72 548.59 478.69 555.88 465.11 573.11 452.25 589.33 423.08 633.36 421.53 635.83 421.53 635.92 421.44 635.92 421.35 635.83 420.9 635.28 418.71 632.54 415.43 628.35 414.51 627.26 413.6 626.07 412.6 624.79 412.6 624.7 412.51 624.79 412.42 624.79L376.68 691.71V691.8C380.15 695.35 384.8 699.36 390.45 703.01 399.84 709.03 412.05 714.13 426.91 714.13 467.48 714.13 490.91 624.7 491.54 622.33 491.54 622.33 491.54 622.24 491.64 622.24 497.65 621.33 502.03 616.41 502.48 615.95H502.57C506.49 616.96 510.69 616.68 514.33 615.04 523.72 611.03 521.81 604.74 521.63 604.1V604.01C529.28 595.44 520.9 589.42 519.9 588.7 519.8 588.7 519.8 588.6 519.9 588.51 520.62 587.97 525 584.32 522.54 577.67 520.81 572.93 512.33 569.19 510.87 568.64 510.78 568.64 510.78 568.55 510.87 568.46 513.42 567.46 537.76 557.34 537.76 551.32Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M413.79 647.31L421.26 636.01V635.92C420.99 635.55 418.71 632.73 415.43 628.62 415.33 628.53 415.24 628.62 415.24 628.62L413.6 647.22C413.6 647.31 413.79 647.4 413.79 647.31Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M365.01 647.49L368.57 682.41C370.94 685.78 373.77 688.79 376.68 691.8 376.68 691.8 383.98 699.27 390.45 703.01L365.11 647.4C365.11 647.4 365.01 647.4 365.01 647.49Z"
        fill="#000000"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M421.81 635.46L413.79 647.31"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M365.01 647.49L368.57 682.41"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M303.12 514.22L309.95 520.96"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M337.39 553.23C329.37 550.96 320.98 546.76 320.98 546.76"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M345.96 529.72C347.42 531.81 349.79 533.18 352.34 533.36"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M369.48 719.69C369.94 715.95 344.05 706.93 320.35 701.55 296.28 695.99 252.25 690.07 249.52 689.7 249.42 689.7 249.42 689.61 249.42 689.61 249.97 688.61 254.16 680.86 258.9 670.74V670.65L195.55 640.11H195.46C187.34 654.33 177.41 673.11 172.85 689.7 159.18 740.66 195.55 743.39 213.78 744.31 231.92 745.22 279.51 741.66 280.24 741.57 280.87 741.75 320.35 753.42 332.11 753.42 342.95 753.42 343.14 748.77 343.05 748.04 343.05 747.95 343.05 747.95 343.14 747.95 344.32 748.04 355.63 748.41 354.9 740.75 354.9 740.66 354.9 740.66 354.99 740.66 356.17 740.48 370.12 737.92 362.19 731.54 357.9 728.08 336.66 719.78 332.74 718.14 332.65 718.14 332.65 717.96 332.74 717.96 343.14 719.05 368.57 726.89 369.48 719.69Z"
        fill="#a66241"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M249.42 689.7L267.02 692.16"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M249.42 689.7L265.74 658.98"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M343.14 747.86L313.97 737.92"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M354.99 740.66L319.43 725.16"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M332.74 717.96L323.9 714.86"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M493.19 560.44L488.08 572.83C488.08 572.83 489.54 591.34 482.25 601.37"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M519.9 588.7C509.87 595.08 500.57 594.35 498.65 592.34 495.92 589.61 496.83 583.23 505.95 579.58"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M521.63 604.01C516.16 606.75 504.12 609.57 500.48 605.01 496.83 600.46 502.3 594.99 502.3 594.99"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M502.48 615.86C499.48 613.58 496.83 610.3 499.11 607.29 499.48 606.75 499.93 606.29 500.48 605.92"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M354.71 517.04C355.26 518.41 355.99 520.6 355.63 521.78 355.26 522.51 354.62 523.15 353.8 523.43"
        stroke="#171717"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M341.68 513.76C343.18 513.76 344.41 512.54 344.41 511.03 344.41 509.51 343.18 508.29 341.68 508.29 340.16 508.29 338.94 509.51 338.94 511.03 338.94 512.54 340.16 513.76 341.68 513.76Z"
        fill="#17151c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M361.28 512.39C362.78 512.39 364.01 511.17 364.01 509.66 364.01 508.15 362.78 506.93 361.28 506.93 359.76 506.93 358.54 508.15 358.54 509.66 358.54 511.17 359.76 512.39 361.28 512.39Z"
        fill="#17151c"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M497.74 609.84C498.2 607.29 499.57 606.38 500.57 605.38 500.57 605.38 500.57 605.29 500.57 605.2 499.84 604.74 497.01 600.73 501.85 594.62 501.85 594.53 501.85 594.53 501.85 594.44 500.84 593.98 492.91 589.88 500.11 583.13V583.04C499.2 582.22 489.27 573.84 488.08 572.83 487.99 572.83 487.99 572.83 487.9 572.93 487.9 576.84 489.45 591.7 482.25 601.37V601.46C487.9 612.12 496.92 610.12 497.74 609.84 497.74 609.94 497.74 609.94 497.74 609.84Z"
        fill="#171717"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M440.31 1012.31C431.38 997.46 411.51 993.44 411.32 963.27 411.32 961.63 411.32 959.81 411.51 957.98 411.6 957.25 411.6 956.62 411.69 955.89 411.69 955.8 411.69 955.8 411.6 955.8L391.91 955.7C384.8 955.7 379.42 961.99 380.6 969.01L383.7 988.25C383.7 988.34 383.61 988.43 383.52 988.34 381.33 986.24 359.82 966.92 325.72 965.46 325.63 965.46 325.63 965.46 325.63 965.55 324.9 972.02 324.36 978.4 323.9 985.24 323.72 987.79 323.63 990.35 323.45 992.81 323.17 999.1 322.81 1005.75 322.81 1012.41L382.34 1018.42 440.31 1012.31Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M440.31 1012.31H322.81C322.72 1012.31 322.72 1012.31 322.72 1012.41 322.63 1017.78 322.63 1023.34 322.81 1028.91 322.81 1029 322.81 1029 322.9 1029H444.69C444.78 1029 444.78 1029 444.78 1028.91 444.78 1024.35 444.05 1020.61 442.96 1017.33 442.14 1015.51 441.32 1013.96 440.31 1012.31Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M413.33 979.68L400.66 984.88"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M419.62 990.16L408.95 998.18"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M383.61 988.43L388.53 1012.22"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M576.78 961.72C562.1 952.33 542.14 957.44 528.46 930.54 527.83 929.09 526.46 926.35 526.28 925.8 526.28 925.71 526.19 925.71 526.19 925.8L511.96 932.37C504.67 935.83 502.21 945.04 506.86 951.78L516.89 966.19C516.98 966.28 516.89 966.37 516.8 966.37 513.79 965.37 485.16 956.89 452.89 971.66L452.8 971.75C454.9 977.58 457.09 983.42 459.55 989.34 460.55 991.71 461.55 994.08 462.56 996.45 465.11 1002.2 468.12 1008.58 471.03 1014.5 471.12 1014.68 472.04 1015.41 472.31 1015.69H472.4L576.6 962.91C576.87 962.72 577.05 961.9 576.78 961.72Z"
        fill="#a9e504"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M580.52 964.64C579.33 963.45 578.05 962.54 576.78 961.72H576.69L471.22 1014.41C471.22 1014.41 471.12 1014.5 471.22 1014.5 473.59 1019.24 476.05 1024.07 478.69 1028.91 478.69 1028.91 478.78 1029 478.78 1028.91L587.72 974.57C587.72 974.57 587.81 974.48 587.72 974.48 585.62 970.2 583.16 967.01 580.52 964.64Z"
        fill="#053061"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M539.13 944.95L530.29 955.61"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M549.25 951.42L544.23 962.81"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M517.16 966.37L531.75 984.24"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M228.82 761.63H253.71L249.7 785.24C248.06 794.63 241.77 801.55 232.2 801.55H231.01"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
      <path
        d="M364.29 834.64C364.29 834.64 375.22 814.59 378.69 808.21"
        stroke="#000000"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity="1"
        fill="none"
      />
    </g>
    <g>
      <path
        d="M252.65 855.22C258 855.22 262.4 859.55 262.4 864.9L262.33 890.23V995.03C262.33 1000.38 258 1004.71 252.65 1004.71 247.3 1004.71 242.97 1000.38 242.97 995.03V864.9C242.89 859.55 247.22 855.22 252.65 855.22Z"
        fill="#fff5f5"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M252.65 855.22C247.22 855.22 242.89 859.55 242.97 864.9V899.04L262.33 890.23 262.4 864.9C262.33 859.55 258 855.22 252.65 855.22Z"
        fill="#3e1010"
        opacity="0.2"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M320.55 1017.69C319.21 1016.51 253.2 958.84 253.2 958.84H252.02C252.02 958.84 186.01 1016.51 184.67 1017.69 183.02 1018.63 181.21 1023.27 183.81 1026.26 185.85 1028.78 189.47 1029.41 192.23 1027.6 192.38 1027.52 228.5 1002.74 250.13 987.87 251.63 986.85 253.51 986.85 255.01 987.87 276.64 1002.74 312.84 1027.52 312.91 1027.6 315.75 1029.41 319.44 1028.7 321.33 1026.26 324.01 1023.19 322.2 1018.63 320.55 1017.69Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M236.91 955.06H268.3C270.19 955.06 271.69 956.56 271.69 958.44V975.28H233.45V958.44C233.53 956.56 235.03 955.06 236.91 955.06Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M233.53 849.09H271.77V875.28C271.77 876.62 270.67 877.8 269.25 877.8H235.97C234.63 877.8 233.53 876.7 233.53 875.28V849.09Z"
        fill="#056154"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
      <path
        d="M196.08 833.51H309.14C316.69 833.51 322.75 839.64 322.75 847.12 322.75 854.67 316.61 860.73 309.14 860.73H196.08C188.53 860.73 182.47 854.59 182.47 847.12 182.47 839.64 188.61 833.51 196.08 833.51Z"
        fill="#41cdfb"
        opacity="1"
        strokeWidth="4"
        stroke="none"
      />
    </g>
  </svg>
);

const HowItWorks = () => (
  <section id="How it Works?" className="howItWorks">
    <div className="container">
      <div className="textCont">
        <div className="text">
          <Text
            className="title"
            css={{
              textGradient: '45deg, #fa203c -20%, #fa6920 100%',
              margin: 0,
            }}
          >
            How it Works ?
          </Text>
          <div className="subtitle">
            Our unique approach to personality assessment combines open-ended
            questions with cutting-edge AI technology. This allows us to capture
            the depth and nuance of each users response, providing more
            customized information and actionable feedback without limiting
            people to predefined categories.
          </div>
        </div>
        <div className="stepsContainer">
          <div id="step1" className="step">
            <div className="title">Complete the Test</div>
            <p className="content">
              Answer a series of open-ended questions designed to assess your
              emotional intelligence.
            </p>
            <div className="num">1</div>
          </div>
          <div id="step2" className="step">
            <div className="title">
              AI <br />
              Analysis
            </div>
            <p className="content">
              Our advanced AI algorithms analyze your responses to provide
              in-depth insights into your emotional intelligence.
            </p>
            <div className="num">2</div>
          </div>
          <div id="step3" className="step">
            <div className="title">Personalized Report</div>
            <p className="content">
              Receive a detailed report outlining your strengths and areas for
              growth, along with actionable steps to help you improve.
            </p>
            <div className="num">3</div>
          </div>
        </div>
      </div>
      <div className="illus">
        <HIllustration />
      </div>
    </div>
  </section>
);

export const Home = () => (
  <div className="home">
    <Hero />
    <AboutUs />
    <HowItWorks />
    <ContactUs />
  </div>
);
